export enum UiMode {
    NONE = "None",
    MOVE = "Move",
    STOCK = "Stock",
    FLOW = "Flow",
    PARAM = "Param",
    DYN_VARIABLE = "Dynamic Variable",
    SUM_VARIABLE = "Sum Variable",
    CONNECT = "Connect",
    IDENTIFY = "Identify",
    EDIT = "Edit",
    STICKY_NOTE = "Sticky Note",
    LOOP_ICON = "Loop Icon",
    DELETE = "Delete",
    RESIZE = "Resize",
};

export function modeFromString(s: string): UiMode | null {
    // TODO Can't we just do this with Object.entries()?
    switch (s.toUpperCase()) {
        case "MOVE":
            return UiMode.MOVE;
        case "STOCK":
            return UiMode.STOCK;
        case "FLOW":
            return UiMode.FLOW;
        case "PARAM":
        case "PARAMETER":
            return UiMode.PARAM;
        case "DYN_VARIABLE":
        case "DYNAMIC VARIABLE":
            return UiMode.DYN_VARIABLE;
        case "SUM VARIABLE":
            return UiMode.SUM_VARIABLE;
        case "CONNECT":
            return UiMode.CONNECT;
        case "IDENTIFY":
            return UiMode.IDENTIFY;
        case "EDIT":
            return UiMode.EDIT;
        case "STICKY NOTE":
            return UiMode.STICKY_NOTE;
        case "LOOP ICON":
            return UiMode.LOOP_ICON;
        case "DELETE":
            return UiMode.DELETE;
        case "NONE":
            return UiMode.NONE;
        case "RESIZE":
            return UiMode.RESIZE;
        default:
            return null;
    }
}

export class UnrecognizedModeError extends Error { }
