enum ComponentType {
    STOCK = "stock",
    FLOW = "flow",
    PARAMETER = "parameter",
    VARIABLE = "variable",
    SUM_VARIABLE = "sum_variable",
    CONNECTION = "connection",
    STATIC_MODEL = "static_model",
    SUBSTITUTION = "substitution",
    STICKY_NOTE = "sticky_note",
    CLD_VERTEX = "cld_vertex",
    CLD_LINK = "cld_link",
    LOOP_ICON = "loop_icon",
};

export default ComponentType;
