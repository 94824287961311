import ComponentType from "./ComponentType";
import FirebaseStaticModel from "./FirebaseStaticModel";

// Represents any object that acts as the "data" field for any FirebaseDataComponent
export interface FirebaseDataObject { }

export abstract class FirebaseEntityBase<DataType extends FirebaseDataObject> {
    public abstract toFirebaseEntry(): [string, Object];
    public abstract withData(d: DataType): FirebaseEntityBase<DataType>;

    protected readonly id: string;
    protected readonly data: DataType;

    constructor(id: string, data: DataType) {
        this.id = id;
        this.data = data;
    }

    public getId(): string {
        return this.id;
    };

    public getData(): DataType {
        return this.data;
    }

    public equals(other: FirebaseEntityBase<any>): boolean {
        // https://stackoverflow.com/questions/201183/how-can-i-determine-equality-for-two-javascript-objects
        function deepEquals(x: any, y: any): boolean {
            const ok = Object.keys, tx = typeof x, ty = typeof y;
            return x && y && tx === 'object' && tx === ty ? (
                ok(x).length === ok(y).length &&
                ok(x).every(key => deepEquals(x[key], y[key]))
            ) : (x === y);
        }
        return deepEquals(this.getData(), other.getData())
    }

    public toString() {
        return `FirebaseEntity: id = ${this.getId()}, `
            + `data = ${Object.entries(this.getData())}`;
    }
}


// Represents all components as they are represented inside Firebase
export abstract class FirebaseComponentBase
    <DataType extends FirebaseDataObject>
    extends FirebaseEntityBase<DataType> {

    public static readonly ID_DELIMITER = "-";

    public getContainingModelId(): string | undefined {
        const idSplit = this.getId().split(FirebaseComponentBase.ID_DELIMITER);
        if (idSplit.length === 1) return undefined;
        else return idSplit
            .slice(0, idSplit.length - 1)
            .join(FirebaseComponentBase.ID_DELIMITER);
    }

    public getUnqualifiedId(): string {
        const id = this.getId()
            .split(FirebaseComponentBase.ID_DELIMITER)
            .at(-1);
        if (!id) throw new Error("Cannot de-qualify id: " + this.getId());
        else return id;
    }

    public asChildOf(
        parent: FirebaseStaticModel,
        dx: number = 0,
        dy: number = 0,
    ): FirebaseComponentBase<DataType> {
        return this.withId(parent.makeChildId(this.getId()));
    }

    public clone(): FirebaseComponentBase<DataType> {
        return this.withId(this.getId());
    }

    public equals(other: FirebaseComponent): boolean {
        return other.getType() === this.getType() && super.equals(other);
    }

    public toFirebaseEntry(): [string, { type: string, data: any }] {
        return [
            this.getId(),
            {
                "type": this.getType(),
                "data": this.getData()
            }
        ];
    }

    public toString() {
        return `${this.getType()}: id = ${this.getId()}, `
            + `data = ${Object.entries(this.getData())}`;
    }

    public abstract getType(): ComponentType;
    public abstract withId(id: string): FirebaseComponentBase<DataType>;
    public abstract withData(d: DataType): FirebaseComponentBase<DataType>;
    public abstract getReadableComponentName(): string;
    public abstract getLabel(): string | null;
}

type FirebaseComponent = FirebaseComponentBase<any>;
export type FirebaseEntity = FirebaseEntityBase<any>

export default FirebaseComponent;
