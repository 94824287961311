const firebaseConfig = {
    apiKey: "AIzaSyBPHxHRgdou7JbuLhsDbfZqDT6j72nLwBA",
    authDomain: "modelcollab.firebaseapp.com",
    databaseURL: "https://modelcollab-default-rtdb.firebaseio.com",
    projectId: "modelcollab",
    storageBucket: "modelcollab.appspot.com",
    messagingSenderId: "388145787482",
    appId: "1:388145787482:web:39438739d24000e034de63",
    measurementId: "G-LC8NJC38E9",
    useEmulators: false
};

export default firebaseConfig;
